.new-style-button-base {
  width: 100%;
  padding: 0.9rem;
  background: #072c85;
  color: white;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(7, 44, 133, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
}

.new-style-button-base:not(:disabled):hover {
  background: #093aa6;
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(7, 44, 133, 0.4);
}

.new-style-button-base:not(:disabled):active {
  transform: translateY(0);
}

.new-style-button-base:disabled {
  background: #072c85;
  opacity: 0.7;
  cursor: not-allowed;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.new-style-button-spinner {
  animation: spin 1s linear infinite;
}
