.new-style-input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.new-style-input-label {
  color: white;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.new-style-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.new-style-input-icon {
  position: absolute;
  left: 16px;
  color: #9CA3AF;
  display: flex;
  align-items: center;
  pointer-events: none;
  transition: color 0.3s ease;
}

.new-style-input-field {
  width: 100%;
  padding: 0.9rem 1.2rem;
  border: 2px solid #D1D5DB;
  border-radius: 32px;
  background: white;
  font-size: 1rem;
  color: #1F2937;
  transition: all 0.3s ease;
  outline: none;
}

.new-style-input-field.with-icon {
  padding-left: 48px;
}

.new-style-input-field.with-action {
  padding-right: 48px;
}

.new-style-input-field:hover:not(:disabled) {
  border-color: #9CA3AF;
}

.new-style-input-field.focused:not(:disabled):not(.error) {
  border-color: #072c85;
  background: #EBF0FF;
  box-shadow: 0 0 0 3px rgba(7, 44, 133, 0.1);
}

.new-style-input-field.error {
  border-color: #FF4444;
  background: white;
}

.new-style-input-field.error:hover:not(:disabled) {
  border-color: #FF3333;
}

.new-style-input-field.error.focused:not(:disabled) {
  border-color: #FF3333;
  background: #FFF0F0;
  box-shadow: 0 0 0 3px rgba(255, 68, 68, 0.1);
}

.new-style-input-field:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: #F3F4F6;
}

.new-style-input-field::placeholder {
  color: #9CA3AF;
}

.new-style-input-action {
  position: absolute;
  right: 16px;
  background: none;
  border: none;
  color: #9CA3AF;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px;
  transition: color 0.3s ease;
}

.new-style-input-action:hover {
  color: #1F2937;
}

.new-style-tooltip {
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background: #FF4444;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: 500;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(255, 68, 68, 0.2);
}

.new-style-tooltip::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: transparent transparent #FF4444 transparent;
}

.new-style-input-container:hover .new-style-tooltip {
  opacity: 1;
  visibility: visible;
}
