.generic-skeleton-container {
  background: white;
  width: 100%;
  padding-top: 34px;
}

.generic-skeleton-form-group {
  margin-bottom: 1.5rem;
}

.generic-skeleton-label {
  height: 20px;
  width: 120px;
  background: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: generic-skeleton-pulse 1.5s infinite;
}

.generic-skeleton-label.wide {
  width: 240px;
}

.generic-skeleton-input {
  height: 40px;
  width: 100%;
  background: #e0e0e0;
  border-radius: 6px;
  animation: generic-skeleton-pulse 1.5s infinite;
}

.generic-skeleton-select {
  height: 40px;
  width: 100%;
  background: #e0e0e0;
  border-radius: 6px;
  animation: generic-skeleton-pulse 1.5s infinite;
}

.generic-skeleton-two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.generic-skeleton-toggle-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.generic-skeleton-toggle {
  width: 48px;
  height: 24px;
  background: #e0e0e0;
  border-radius: 12px;
  animation: generic-skeleton-pulse 1.5s infinite;
}

.generic-skeleton-section-group {
  margin-top: 2rem;
}

.generic-skeleton-section-title {
  height: 24px;
  width: 100px;
  background: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 1rem;
  animation: generic-skeleton-pulse 1.5s infinite;
}

.generic-skeleton-toggle-list {
  padding-left: 1rem;
}

@keyframes generic-skeleton-pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
