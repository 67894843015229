.new-style-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  min-width: 300px;
  max-width: 450px;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 12px;
  color: white;
  animation: slideIn 0.3s ease-out;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.new-style-notification-info {
  background: linear-gradient(135deg, #2196f3, #1976d2);
}

.new-style-notification-danger {
  background: linear-gradient(135deg, #f44336, #d32f2f);
}

.new-style-notification-warning {
  background: linear-gradient(135deg, #ff9800, #f57c00);
}

.new-style-notification-icon {
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.new-style-notification-content {
  flex: 1;
  font-size: 0.95rem;
  line-height: 1.5;
}

.new-style-notification-close {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: opacity 0.2s;
  margin-left: 12px;
}

.new-style-notification-close:hover {
  opacity: 1;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
