.attendance-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2400;
  overflow-y: auto;
  padding: 2rem 1rem;
}

.attendance-content {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 600px;
  position: relative;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  margin: auto;
}

.attendance-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4b5563;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.attendance-close-button:hover {
  background: white;
  color: #25B6BF;
}

.attendance-header {
  background: linear-gradient(135deg, #25B6BF 0%, #00aa80 100%);
  padding: 24px;
  color: white;
  border-radius: 16px 16px 0 0;
}

.attendance-user-info {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

.attendance-avatar {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attendance-details h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.attendance-document {
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 13px;
  margin-top: 4px;
}

.attendance-shift-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.attendance-date {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  background: rgba(255, 255, 255, 0.2);
  padding: 8px 12px;
  border-radius: 8px;
}

.attendance-workplace {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.attendance-pill {
  display: flex;
  align-items: center;
  gap: 6px;
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 13px;
}

.attendance-pill-clickable {
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.attendance-pill-clickable:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.4);
}

.attendance-timeline {
  padding: 20px;
}

.attendance-timeline-entry {
  background: #e9f8f8;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid #25B6BF;
}

.attendance-timeline-entry.attendance-disabled {
  opacity: 0.5;
  pointer-events: none;
  background: #f3f4f6;
  border-color: #d1d5db;
}

.attendance-time-marker {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.attendance-marker {
  background: #fff;
  border: 2px solid #25B6BF;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #25B6BF;
}

.attendance-disabled .attendance-marker {
  border-color: #d1d5db;
  color: #d1d5db;
}

.attendance-time {
  flex: 1;
}

.attendance-label {
  font-weight: 600;
  color: #111827;
  font-size: 15px;
  display: block;
  margin-bottom: 2px;
}

.attendance-timestamp {
  color: #25B6BF;
  font-size: 13px;
}

.attendance-entry-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid rgba(37, 182, 191, 0.2);
}

.attendance-marked-by {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #4b5563;
  font-size: 13px;
  background: #fff;
  padding: 6px 12px;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.attendance-action-buttons {
  display: flex;
  gap: 8px;
}

.attendance-action-btn {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4b5563;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.attendance-action-btn:not(:disabled):hover {
  background: #e9f8f8;
  border-color: #25B6BF;
  color: #25B6BF;
}

.attendance-action-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #f3f4f6;
}

.attendance-action-btn.warning {
  color: #ef4444;
  border-color: #ef4444;
  background: #fef2f2;
}

.attendance-action-btn.warning:hover {
  background: #fee2e2;
  color: #dc2626;
}

.attendance-tooltip {
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background: #1f2937;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 10;
}

.attendance-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #1f2937 transparent transparent transparent;
}

.attendance-action-btn:hover .attendance-tooltip {
  opacity: 1;
}

.attendance-edit-time {
  color: #25B6BF;
  font-size: 13px;
  text-decoration: underline;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.attendance-edit-time:disabled {
  color: #d1d5db;
  cursor: not-allowed;
  text-decoration: none;
}

.attendance-date-picker-modal {
  background: white;
  border-radius: 16px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  position: relative;
}

.attendance-time-picker {
  margin: 16px 0;
  text-align: center;
}

.attendance-time-picker input {
  padding: 8px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 16px;
}

.attendance-save-button {
  width: 100%;
  background: #25B6BF;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.attendance-save-button:hover:not(:disabled) {
  background: #32b6bf;
}

.attendance-save-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.attendance-skeleton {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 600px;
  overflow: hidden;
}

.attendance-skeleton-header {
  background: linear-gradient(135deg, #25B6BF 0%, #00aa80 100%);
  padding: 24px;
}

.attendance-skeleton-user {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

.attendance-skeleton-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
}

.attendance-skeleton-details {
  flex: 1;
}

.attendance-skeleton-name {
  height: 24px;
  width: 180px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  margin-bottom: 8px;
}

.attendance-skeleton-document {
  height: 20px;
  width: 120px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
}

.attendance-skeleton-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.attendance-skeleton-date {
  height: 36px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.attendance-skeleton-workplace {
  display: flex;
  gap: 8px;
}

.attendance-skeleton-pill {
  height: 32px;
  width: 140px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}

.attendance-skeleton-timeline {
  padding: 20px;
}

.attendance-skeleton-entry {
  height: 120px;
  background: #e9f8f8;
  border-radius: 12px;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
}

.attendance-skeleton-entry::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0) 0,
                  rgba(255, 255, 255, 0.2) 20%,
                  rgba(255, 255, 255, 0.5) 60%,
                  rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.attendance-loader {
  background: white;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.attendance-spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.attendance-map-modal {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 800px;
  position: relative;
  max-height: 85vh;
  overflow: hidden;
}

.attendance-map-container {
  height: 600px;
  display: flex;
  flex-direction: column;
}

.attendance-map-buttons {
  display: flex;
  gap: 8px;
  padding: 16px;
  background: white;
  border-bottom: 1px solid #e2e8f0;
}

.attendance-map-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: white;
  color: #4b5563;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.attendance-map-button:hover {
  border-color: #25B6BF;
  color: #25B6BF;
}

.attendance-map-button.active {
  background: #25B6BF;
  border-color: #25B6BF;
  color: white;
}

.attendance-photo-modal {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 800px;
  position: relative;
  max-height: 85vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attendance-photo-modal img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 85vh;
}

.attendance-form-section {
  margin-top: 20px;
  background: #fff;
  border-radius: 12px;
  padding: 16px;
  border: 1px solid #e2e8f0;
}

.attendance-form-title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #111827;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
}

.attendance-form-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.attendance-form-empty {
  text-align: center;
  padding: 32px 16px;
  color: #6b7280;
  background: #f9fafb;
  border-radius: 8px;
  border: 2px dashed #e5e7eb;
}

.attendance-form-empty-icon {
  margin-bottom: 12px;
  color: #9ca3af;
}

.attendance-form-empty-text {
  font-size: 14px;
  line-height: 1.5;
}

.attendance-form-empty-title {
  font-weight: 500;
  color: #4b5563;
  margin-bottom: 4px;
}

.attendance-form-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
}

.attendance-form-completed {
  cursor: pointer;
}

.attendance-form-completed:hover {
  background: #e9f8f8;
  border-color: #25B6BF;
}

.attendance-form-pending {
  opacity: 0.7;
  cursor: not-allowed;
}

.attendance-form-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.attendance-form-completed .attendance-form-icon {
  color: #00aa80;
}

.attendance-form-pending .attendance-form-icon {
  color: #9ca3af;
}

.attendance-form-info {
  flex: 1;
}

.attendance-form-name {
  display: block;
  font-weight: 500;
  color: #111827;
  margin-bottom: 2px;
}

.attendance-form-date {
  display: block;
  font-size: 13px;
  color: #6b7280;
}

.attendance-form-action {
  display: flex;
  align-items: center;
}

.attendance-form-view {
  font-size: 13px;
  color: #25B6BF;
  font-weight: 500;
}

.attendance-form-modal {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 800px;
  position: relative;
  max-height: 85vh;
  overflow: hidden;
}

@media (max-width: 640px) {
  .attendance-content,
  .attendance-skeleton {
    width: 95%;
    margin: 16px;
  }

  .attendance-header,
  .attendance-skeleton-header {
    padding: 20px;
  }

  .attendance-timeline,
  .attendance-skeleton-timeline {
    padding: 16px;
  }

  .attendance-entry-details {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .attendance-action-buttons {
    width: 100%;
    justify-content: flex-end;
  }

  .attendance-map-buttons {
    flex-wrap: wrap;
  }

  .attendance-map-button {
    flex: 1;
    min-width: 120px;
    justify-content: center;
  }
}
