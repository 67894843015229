@import "../../../assets/styles/variables";

.MuiPaper-root {
    &.MuiPaper-elevation1 {
        box-shadow: inherit;
    }
}
.header__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .searchbar__main {
        background: #FEFEFE;
        box-shadow: 0px 1px 4px rgba(52, 60, 68, 0.1);
        border-radius: 8px;
        .searchbar__input {
            min-width: 400px;
            &.MuiPaper-root {
                &.MuiPaper-rounded {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
            .MuiInputBase-root {
                padding-right: 0;

                fieldset {
                    border: 0;
                }
            }
            .searchbar__icon__main {
                width: 48px;
                height: 48px;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                background: linear-gradient(219.93deg, #00AA80 12.52%, #25B6BF 136.91%);
                border-radius: 8px;

                svg {
                    fill: $white;
                }
            }
        }
    }
    .second__options {
        @apply flex gap-5 relative;

        .filter-button {
            @apply w-12 h-10;
        }
        .filter-menu {
            @apply hidden bg-white rounded-lg p-2;

            .label-filter-menu-item {
                @apply flex justify-start items-center px-2 rounded-t-lg;
                background-color: #FBFBFB;
                border-bottom: 1px solid rgba(0, 170, 128, 0.1);
                height: 50px;
            }

            .filter-menu-item {
                @apply px-2;
                .textfield__label {
                    color: $black;
                }
                .MuiFormControl-root {
                    @apply w-full;
                    .multiple-select {
                        .MuiSelect-select {
                            padding: 0.5938rem 2rem 0.5938rem 0.875rem;
                        }
                    }
                }
                .search__main {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    align-content: center;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    gap: 5px;
                    padding: 6px 10px;
                    margin-bottom: 5px;
                    background-color: #FBFBFB;

                    .MuiInputBase-root {
                        &:before,
                        &:after,
                        &:hover {
                            border: none
                        }
                    }
                }
                .accordion__main {

                    .dates__main {
                        @apply flex flex-row justify-center items-center content-center gap-3 mb-2;
                        .dates__col {
                            .date-picker__main {
                                .text-field {
                                    .MuiOutlinedInput-root {
                                        background: #FFFFFF;
                                        padding-right: 8px;
                                        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(134, 143, 160, 0.16);
                                        border-radius: 6px;
                                        .MuiOutlinedInput-input {
                                            @apply py-3 text-xs pl-2;
                                        }
                                    }
                                    .MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root {
                                        fill: #3A3F4D;
                                        width: 18px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .filter-footer {
                @apply flex flex-row justify-end items-center content-center gap-5;
                padding-right: 10px;
                height: 50px;

                .MuiButtonBase-root {
                    width: 66px;
                    height: 22px;
                    margin-bottom: 0;
                    span {
                        @apply text-sm;
                    }
                }
            }
            &--open {
                @apply block absolute top-14 overflow-x-auto right-0;

                z-index: 2;
                min-width: 340px;
                max-height: 74vh;

                /* width */
                &::-webkit-scrollbar {
                    width: 7px;
                    border-radius: 20px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    @apply bg-white;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #B5B7BA;
                    border-radius: 20px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
        }
        .multiple-select {
            @apply bg-white;
        }
    }

}
.show-filters__main {
    @apply mb-6;
    .filters__options {
        @apply mb-2 flex flex-row items-center content-center justify-start;
        gap: 5px;

        .filters-length {
            color: $black;
        }
        .clean-filter__content {
            @apply pl-2;
            border-left: 1px solid rgba(37, 182, 191, 0.1);
            .clean-filter {
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.03em;
                color: $aquamarine;

                &:hover {
                    @apply cursor-pointer underline;
                }
            }
        }
    }
    .filters__items {
        .MuiChip-root {
            @apply rounded mr-2;
            background: rgba(0, 170, 128, 0.1);
        }
        .MuiChip-label {
            .key-text {
                color: $aquamarine;
            }
            .value-text {
                color: $black;
            }
        }
    }
}
.selected-actions__main {
    @apply w-full h-16 bg-white rounded-t-md;
    border-bottom: 1px solid rgba(#DFDFDF, 0.4);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: nowrap;
    gap: 26px;
    padding-left: 70px;

    .selected__field {
        width: 200px;

        .MuiAutocomplete-inputRoot {
            height: 35px;
            padding: 0 9px;
        }
    }
    .selected-text {
        @apply font-regular text-sm leading-4;
        letter-spacing: 0.05em;
        color: #687182;

        &--number {
            @apply font-semibold;
        }
    }
}
.MuiTable-root {
    .MuiTableHead-root {
        border-bottom: 0.25rem solid #DFDFDF;

        .MuiTableRow-root {
            .MuiTableCell-root {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.375rem;
                color: $dark;
                padding: 0rem 1rem;
                height: 3.75rem;

                .MuiTableSortLabel-root {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;

                    .MuiBox-root {
                        display: none;
                    }
                }
            }
        }
    }
    .MuiTableBody-root {
        .MuiTableRow-root {
            .MuiTableCell-root {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.375rem;
                color: $dark-gray;
                height: 3.125rem;
                padding: 0rem 1rem;
                position: relative;
                max-width: 200px;
                .status__main {
                    width: 5rem;
                    height: 1.25rem;
                    background: #E1FCEF;
                    border-radius: 0.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    flex-wrap: nowrap;
                    flex-direction: row;

                    &.status__filled {
                        &--bg {
                            &-green {
                                background-color: #E1FCEF;
                            }
                            &-purple {
                                background-color: #EDEDFC;
                            }
                            &-yellow {
                                background-color: $yellow;
                            }
                            &-red {
                                background-color: #FFEDEF;
                            }
                            &-gray {
                                background-color: #F6F6F6;
                            }
                        }
                    }
                    &.status__outlined {
                        background-color: $white;
                        border-width: 0.0625rem;
                        border-style: solid;
                        &--bg {
                            &-green {
                                border-color: #E1FCEF;
                            }
                            &--purple {
                                border-color: #807CEA;
                            }
                            &-yellow {
                                border-color: $yellow;
                            }
                            &-red {
                                border-color: #E25822;
                            }
                        }
                    }
                    .status__text {
                        font-weight: 500;
                        font-size: 0.625rem;
                        line-height: 0.75rem;
                        text-align: center;
                        margin-bottom: 0;
                        &--black {
                            color: #000000;
                        }
                        &--darkgray {
                            color: #c3c3c3;
                        }
                        &--green {
                            color: #14804A;
                        }
                        &--purple {
                            color: #807CEA;
                        }
                        &--yellow {
                            color: $yellow;
                        }
                        &--red {
                            color: #E25822;
                        }
                        &--white {
                            color: $white;
                        }
                    }
                }

                .switch__main {
                    .MuiFormGroup-root {
                        @apply content-start;
                        .MuiSwitch-root {
                            @apply ml-0;
                        }
                    }
                }

                .actions-menu {
                    @apply hidden absolute bg-white py-1;

                    top: 10px;
                    left: 50px;
                    z-index: 2;

                    &--open {
                        @apply block;
                    }
                    .actions-menu-item {
                        @apply px-3 pb-1;
                        color: $black;
                        &--green {
                            color: $main;
                        }
                        &--red {
                            color: rgba(211, 0, 0, 1);
                        }
                        &:hover {
                            background-color: rgba(0, 0, 0, 0.04);
                            cursor: pointer;
                            &--green {
                                color: $main;
                            }
                            &--red {
                                color: rgba(211, 0, 0, 1);
                            }
                        }
                    }
                }

                .stores__cell {
                    @apply flex flex-row flex-nowrap items-center justify-start;
                    gap: 5px;

                    .number__text {
                        @apply text-center font-regular text-sm;
                        width: 18px;
                        height: 18px;
                        background-color: $main;
                        color: $white;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 18px;
                        letter-spacing: 0.05em;
                    }
                }

                .table-cell {
                    &--green, .green {
                        color: $main;
                    }
                }

                .hyperlink {
                    color: $hyperlink;
                }
            }
        }
    }

    .MuiCheckbox-root {
        &.Mui-checked,
        &.MuiCheckbox-indeterminate {
            color: $main;
        }
    }
}
.footer__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 15px;

    p {
        color: $dark-gray;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }
    .right-side {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        .row-per-page-select {
            .MuiSelect-select {
                padding: 5px 20px 5px 15px;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
            }
            svg {
                display: none;
            }
        }
        .MuiButtonBase-root {
            border-radius: 0;
            height: 30px;
            text-align: center;
            width: 30px;
            background-color: $light-gray;

            svg {
                fill: $black;
            }
            &:hover {
                background-color: $main;

                svg {
                    fill: $white;
                }
            }
            &.table-button {
                &--disabled {
                    svg {
                        fill: $dark-gray;
                    }
                }
            }
        }
        .pages__main {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .pages__content {
                cursor: pointer;
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                flex-direction: row;
                flex-wrap: nowrap;

                .pages__number {
                    color: $dark-gray;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                }
                &:hover {
                    background-color: $main;
                    .pages__number {
                        color: $white;
                    }
                }
                &--active {
                    background-color: $main;
                    .pages__number {
                        color: $white;
                    }
                }
            }
        }
    }
}

.MuiModal-root {
    .MuiPaper-root {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            position: relative;
            padding-top: 8px;
            padding-bottom: 8px;
            outline: 0;

            .MuiMenuItem-root {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.05em;
                display: flex;
                justify-content: space-around;
                align-items: center;
                position: relative;
                text-decoration: none;
                min-height: 32px;
                padding-top: 0;
                padding-bottom: 0;
                box-sizing: border-box;
                white-space: nowrap;
                padding-left: 16px;
                padding-right: 16px;
                idth: 100%;
                &.MuiButtonBase-root {
                    &:hover {
                        background-color: rgba(242, 240, 249, 1);
                    }
                }
                &.filter-menu-item {
                    &.MuiButtonBase-root {
                        @apply pb-2 mb-3 flex flex-col items-start content-start flex-nowrap;
                        .MuiFormControl-root {
                            @apply w-full;
                        }
                    }
                }
            }
        }
    }
}
