@import "../../../assets/styles/variables";

.MuiFormControlLabel-root {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
  
  &.MuiFormControlLabel-labelPlacementStart {
    margin: 0;
  }
  .MuiTypography-root {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #737779;
  }
}
.switch-kit__main {
  &.MuiSwitch-root {
    height: 24px;
    width: 44px;
    padding: 0;
    border-radius: 50px;
  }

  .MuiSwitch-switchBase {
    margin: 2px 1px 1px 1px;
    padding: 0;
    transform: translateX(1px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: #fff;
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    & + .MuiSwitch-track {
      opacity: 1;
      background-color: #BCC7D1;
    }
    
    &.Mui-checked {
      background-color: $main;
      color: #fff;
      transform: translateX(18px);

      & + .MuiSwitch-track {
        opacity: 1;
        background-color: $main;
      }
    }
    .MuiSwitch-thumb {
      background-color: $white;
      width: 21px;
      height: 21px;
    }
  }

}