@import "../../../assets/styles/variables";

.btn--disabled.btn--outlined.MuiButton-outlined {
  border-color: #ABC2D4 !important;
  cursor: not-allowed;
}

.attendance-modal__main {
  display: flex;
  .attendance-description__main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .date {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #737779;
    }
    .attendance-info {
      .info-title {
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        color: #25B6BF;
      }
      .info-dni {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #3A3F4D;
      }
    }

    .attendance-campaign {
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #3A3F4D;
      }
    }
  }
  .attendance-time__main {
    width: 100%;
    .check {
      display: flex;
      flex-direction: row;
      height: 100px;
      width: 100%;

      .first-side {
        flex: 1;
        .time__main {
          margin-top: 5px;
          .time__row {
            .time__alert {
              position: absolute;
              font-weight: 400;
              font-size: 11px;
              line-height: 24px;
              letter-spacing: 0.38px;
              color: #E25822;
            }
          }
        }
      }

      .attendance-role {
        width: 100px;
        height: 22px;
        margin-top: 8px;

        .role-content {
          color: $white;
          width: 80px;
          height: 20px;
          border-radius: 15px;

          display: flex;
          justify-content: center;
          align-items: center;

          &--user {
            background-color: #737779;
          }
          &--supervisor {
            background-color: #BCC7D1;
          }

          .role {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 1450515;
}


