@import "../../assets/styles/variables";

.new-style-login-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-image: url('https://titaskdomain.s3.us-east-2.amazonaws.com/imagenes_libres/temps/portrait.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.new-style-login-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

.new-style-login-form {
  width: 100%;
  max-width: 400px;
  position: relative;
  z-index: 1;
}

.new-style-login-logo {
  //width: 360px;
  width: 100%;
  max-width: 360px;
  height: auto;
  margin: 0 auto 0;
  display: block;
}

.country-selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem 0.75rem;
  margin-bottom: 2rem;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
}

.country-button {
  flex: 0 0 auto;
  background: none;
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 0.25rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  width: calc(20% - 0.6rem);
  min-width: 40px;
  display: flex;
  justify-content: center;
}

.country-button:hover {
  transform: translateY(-2px);
}

.country-button.selected {
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.country-button img {
  width: 32px;
  height: 24px;
  border-radius: 4px;
  object-fit: cover;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

.country-name {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 0.75rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.country-button:hover .country-name,
.country-button.selected .country-name {
  opacity: 1;
}

.new-style-login-links {
  margin-top: 2rem;
  text-align: center;
  color: white;
  font-size: 1rem;
  line-height: 1.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-weight: 300;
}

.new-style-login-link {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease;
  opacity: 0.9;
  font-weight: 600;
}

.new-style-login-link:hover {
  border-bottom-color: white;
  opacity: 1;
}

.country-container {
  margin: 2rem 0;
}

.new-style-text {
  p {
    color: $white;
    text-align: center;
  }
}

.new-style-wa-link{
  color: $white;
}

.new-style-divisor {
  background-color: $border-gray;
  filter: blur(0.5px);
  height: 1px;
  margin: 0.5rem auto;
  width: 155px;
}
