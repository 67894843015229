@import "../../../assets/styles/base";

.divider {
  background-color: #F4F4F4;
  height: 1px;
  margin: auto;
  width: 145px;
}

.countries_container {
  text-align: center;
  p {
    color: $white;
    text-align: center;
  }
  &--secondary {
    p {
      color: $dark-gray;
      text-align: center;
    }

    .country__item {
      background: #F4F4F4;
      border: 1px solid #BDBDBD;

      &--selected {
        background: rgba(0, 170, 128, 0.9);
      }
    }
  }
}
.countries__content {
  display: flex;
  align-content: center;
  align-items: stretch;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.country__item {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid $white;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  width: 34px;
  height: 24px;
  position: relative;

  &--selected {
    background: rgba(0, 170, 128, 0.5);
  }

  &:hover {
    background: rgba(0, 170, 128, 0.8);
  }
}

.country__item__none {
  background-color: transparent;
  color: $white;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  width: 52px;
  height: 40px;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: row;

  &--selected {
    color: $black;
  }

  &:hover {
    color: $black;
  }
}

.country__item__img {
  align-self: center;
  width: 16px;
  margin: auto;
}

.country__item__extra {
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: $weight-extrabold;
  color: $black;
}

.text__main {
  margin-top: 16px;
  margin-bottom: 16px;
}
